import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
//初始化路由
const routes = [
  {
    path: "/",
    redirect: "/CarHome",
  },
  {
    path: "/CarHome",
    name: "CarHome",
    component: () => import("../view/CarHome.vue"),
  },
  {
    path: "/MiniProgram",
    name: "MiniProgram",
    component: () => import("../view/MiniProgram.vue"),
  },
  {
    path: "/CarRegulation",
    name: "CarRegulation",
    component: () => import("../view/CarRegulation.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: () => import("../view/ContactUs.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  routes,
});
export default router;
