import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "animate.css";
// import animated from "wowjs/css/libs/animate.css";
import "wowjs/css/libs/animate.css";
// Vue.use(animated);
Vue.prototype.$axios = axios.create({
  baseURL: "https://automobile.luomausedcar.com/prod-api/wx/",
  timeout: 10000,
});
// import "@/utils/rem.js";
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
