<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./assets/css/reset.css";
@import "./assets/css/quill.snow.css";
* {
  font-family: Microsoft YaHei, Microsoft YaHei;
  // overflow-x: scroll; /* 设置溢出滚动 */
  overflow-y: scroll;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
#app {
  margin: 0 auto;
}
</style>
